


function Placeholder() {
  return (
    <div className="intro-header">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="intro-message">
                                                <h1 id="hdrDescription">Soon to be the www. of Excite Distribution (Pty) Ltd.</h1>
                                                <hr className="intro-divider"/>
                                                <h2> <a className="email-address" id="lnkEmailAddress" href="mailto:denim%40excitedistribution.co.za">denim@excitedistribution.co.za</a></h2>
                                            </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Placeholder;
