function About() {
    return (
        <div id="about" style={{padding: "50px 50px 0px 50px"}} className="container">
            <span style={{textAlign: "center", paddingBottom: "50px"}}>
                <h1>ABOUT US</h1>
                <br/>
                <hr/>
                <br/>

                <h3 style={{textAlign: "center", paddingBottom: "50px"}}>
                    some text to describe what excite distribution is about,
                    we should probably come up with something thats nice to read here that,
                    is a brief statement of what you do.

                    in the meantime, im going to start speaking latin,
                    lorem ipsum lorem ipsum blah blah latin text here to fill the gap
                </h3>
            </span>
            
        </div>
    )
}

export default About;