import './App.css';
import Placeholder from './Pages/Placeholder';
import Index from './Pages/Index';
import React from "react";



class App extends React.Component {
  constructor() {
    super();
    this.state = {
      debugMode: false
    }
  }

  render(){
    window.addEventListener("launchDevMode", () => {
      this.setState({debugMode: window.getDevMode()})
  }, false);
  
    var isDebugMode = this.state.debugMode;

    if(!isDebugMode) {
      return (<Placeholder/>);
    } else {
      return (<Index/>);

    }
  }
}

export default App;
