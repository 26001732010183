

import Model from '../Components/Model';
import About from './Home/about';

import Description from './Home/description';

import Products from './Home/products';

const { default: SliderCarousel } = require("../Components/Carousel");

function Main() {

    var items = [
    {src: "https://via.placeholder.com/900x900.png"},
    {src: "https://via.placeholder.com/900x900.png"},
    {src: "https://via.placeholder.com/900x900.png"}
]


    return (
      <div  id="home" style={{marginTop: "125px"}}>
            <Model/>

          <div className="align-middle">
              <SliderCarousel height={45} items={items} hasControls={true}  hasIndicators={true}/>
          </div>

            <About/>


            <Description/>


            <Products/>
         
      </div>
    );
  }
  
  export default Main;
  