function handleActivePageHash() {
    // const hash = window.location.hash == "" ? "#home" : window.location.hash;
    // var elements = document.getElementsByClassName("nav-item");
    // for (var x = 0; x < elements.length; x++) {
    //     var element = elements[x];
    //     var navItemText = element.children[0].innerHTML;
    //     var navLink = element.children[0];

    //     if (`#${navItemText.toLowerCase()}` == hash) {
    //         navLink.classList.add("active")
    //     } else {
    //         navLink.classList.remove("active")
    //     }
    // }
}

function handleActivePage() {

    const homeElement = document.getElementById("navbar");
    const aboutElement = document.getElementById("about");
    const descriptionElement = document.getElementById("description");
    const productsElement = document.getElementById("products");

    var homeNavEl;
    var aboutNavEl;
    var descriptionNavEl;
    var productsNavEl;


    var elements = document.getElementsByClassName("nav-item");
    // const 
    for (var x = 0; x < elements.length; x++) {
        var element = elements[x];
        var navItemText = element.children[0].innerHTML;
        var navLink = element.children[0];

        switch (`${navItemText.toLowerCase()}`) {
            case 'home':
                homeNavEl = navLink;
                break;
            case 'about':
                aboutNavEl = navLink;
                break;
            case 'description':
                descriptionNavEl = navLink;
                break;
            case 'products':
                productsNavEl = navLink;
                break;
        }

    }

    var homeStart = 0;

    // var pageHeight = document.body.scrollHeight;
    var aboutStart =  document.getElementById("carouselExampleCaptions").scrollHeight;
    var descriptionStart = aboutStart + aboutElement.scrollHeight;
    var productsStart = descriptionStart + descriptionElement.scrollHeight;

    function handleClassList(element) {
        switch(element) {
            case "home":
                homeNavEl.classList.add('active');
                aboutNavEl.classList.remove('active');
                descriptionNavEl.classList.remove('active');
                productsNavEl.classList.remove('active');
                break;
            case "about":
                aboutNavEl.classList.add('active');
                homeNavEl.classList.remove('active');
                descriptionNavEl.classList.remove('active');
                productsNavEl.classList.remove('active');
                break;
            case "description":
                descriptionNavEl.classList.add('active');
                homeNavEl.classList.remove('active');
                aboutNavEl.classList.remove('active');
                productsNavEl.classList.remove('active');
                break;
            case "products":
                productsNavEl.classList.add('active');
                descriptionNavEl.classList.remove('active');
                homeNavEl.classList.remove('active');
                aboutNavEl.classList.remove('active');
        }
    }

    var currentScroll = window.scrollY;
    if (currentScroll >= homeStart && currentScroll <= aboutStart) {
        handleClassList("home");
    } else if(currentScroll >= aboutStart && currentScroll <= descriptionStart) {
        handleClassList("about");
    } else if (currentScroll >= descriptionStart && currentScroll <= productsStart) {
        handleClassList("description");
    } else if (currentScroll >= productsStart) {
        handleClassList("products");
    }




}

function Nav() {
    setInterval(() => handleActivePageHash(), 100);
    setInterval(() => handleActivePage(), 100);



    return (


        <nav id="navbar" className="navbar navbar-light bg-light fixed-top">
            <a className="navbar-brand" href="#">
                <img src="/img/FullLogo.png" className="d-inline-block align-top nav-logo" alt="" loading="lazy" />
            </a>
            <ul className="nav ">
                <li className="nav-item">
                    <a className="nav-link" href="#home">Home</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#about">About</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#description">Description</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#products">Products</a>
                </li>

                <li className="nav-item" data-toggle="modal" data-target="#exampleModal">
                    <button className="nav-link" href="#contact">Contact Us</button>
                </li>
            </ul>
        </nav>

    )
}

export default Nav;