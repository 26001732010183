import React from 'react';



const defaultState = {
    Message: "",
    Subject: ""
}


class Model extends React.Component {


    constructor(props) {
        super(props);
        this.state = defaultState;
    }
    componentDidMount() {

    }

    componentDidUpdate() {
        
        console.log("update?")
    }

    componentWillUnmount() {
        //we need to clear fields here
        console.log("unmount")
        this.setState(defaultState);
    }

    handleInputChange = (fieldName, event) => {
        const value = event.target.value;
        const change = {};
        change[fieldName] = value;
        this.setState(change);
    }

    generateMailLink = () => {
        const {Message, Subject} = this.state;
        const mailto = `mailto:denim@excitedistribution.co.za?subject=${Subject}&body=${Message}&`;
        return mailto;
    }


    

    closeModel = () => {
        // const model = document.getElementById("exampleModal");
        // const backdrop = document.getElementsByClassName("modal-backdrop")[0];


        // backdrop.classList.remove("show");
        // backdrop.classList.add("hide");

        // model.classList.add("hide");
        // model.classList.remove("show");

        // model.style.display = "none";
        // model.style.paddingRight = "0px";
    }

    // openModel = () => {
    //     const model = document.getElementById("exampleModal");
    //     model.classList.remove("hide");
    //     model.classList.add("show");

    //     model.style.display = "block";
    //     model.style.paddingRight = "17px";
    // }   

    render() {

    return (
        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
             
                <div className="modal-body">
                  <h4 className="center"> Want to get in touch with us, or have any questions? <br/><br/> Feel free to get in touch with us below:</h4>
                    <br/>
                    <br/>

                    <form>
                    
                    <div className="row">
                     
                        <div className="col-sm-12">

                            <div className="form-group">
                                <label >Subject</label>
                                <input onChange={(el) => this.handleInputChange("Subject", el)}  className="form-control" />
                            </div>
                        </div>

                        <div className="col-sm-12">

                            <div className="form-group">
                                <label >Message</label>
                                <textarea onChange={(el) => this.handleInputChange("Message", el)}  className="form-control" />
                            </div>
                        </div>

                    </div>


                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <a  onClick={() => this.closeModel()} href={this.generateMailLink()}><button className="btn btn-primary" > Send Email </button> </a>
                </div>
                </div>
            </div>
        </div>
    )
}

}

export default Model;