import React from "react";
import Nav from '../Layout/Nav';
import Footer from '../Layout/Footer';

import Main from "./Main";
import Placeholder from "./Placeholder";

function Index() {



        return (
        <>
            <Nav />
            <Main/>
             <Footer/> 
        </>
        );

}

export default Index;
