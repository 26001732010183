

import 'react-multi-carousel/lib/styles.css';

import Carousel from 'react-multi-carousel';

function renderCard(item) {
    return (
        <div style={{margin: '0 10px'}} className="card" >
            <img  src={item.src} className="card-img-top" alt="..."/>
            <div style={{textAlign: "center", minHeight: "10em"}} className="card-body ">
            <h5 className="card-title">{item.title}</h5>
            <p className="card-text">{item.description}</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
            </div>
      </div>
    );
};

function Products() {


    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 5
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    var items = [
        {src: "https://via.placeholder.com/500x900.png", title: "Item title 1", description: "description of each item/price of item"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 2", description: "R 100.00"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 3", description: "R 150.00"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 4", description: "description of each item/price of item"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 5", description: "R 100.00"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 6", description: "R 150.00"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 7", description: "description of each item/price of item"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 8" , description: "R 100.00"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 9", description: "R 150.00"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 10", description: "description of each item/price of item"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 11", description: "R 100.00"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 12", description: "R 150.00"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 13", description: "description of each item/price of item"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 14", description: "R 100.00"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 15", description: "R 150.00"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 16", description: "description of each item/price of item"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 17", description: "R 100.00"},
        {src: "https://via.placeholder.com/900x900.png", title: "Item title 18", description: "R 150.00"}
    ]
    return (
        <div id="products"  style={{padding: "50px"}} >
                <span style={{textAlign: "left"}}>
                <h1>CHECKOUT SOME PRODUCTS HERE</h1>
            </span>
            <br/>
            <div>

            <Carousel   infinite={true} responsive={responsive}>
                    {items.map((item) => {
                        return renderCard(item);
                    })}
            </Carousel>
        </div>

    </div>
    )
}

export default Products;