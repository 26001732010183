function Carousel(props) {
    const { height, items, hasControls, hasIndicators } = props;
    var heightInEm = height ? `${height}em` : "25em";


    return (

        <div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel">

            {
                hasIndicators ?
                    <ol className="carousel-indicators">
                        {items && items.map((item, index) => {
                            return (
                                <li key={index} data-target="#carouselExampleCaptions" data-slide-to={`${index}`} className={index == 0 ? "active" : ""}></li>
                            );
                        })}
                    </ol> : null
            }



            <div style={{ height: heightInEm }} className="carousel-inner">

                {
                    items && items.map((item, index) => {

                        return (
                            <div key={index} style={{ height: heightInEm }} className={`carousel-item ${index == 0 ? "active" : ""}`}>
                                <img style={{ height: heightInEm }} src={item.src} className="d-block w-100" alt="..." />
                                <div className="carousel-caption d-none d-md-block">
                                    <h5>slide label {`${index + 1}`}</h5>
                                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                </div>
                            </div>
                        );
                    })
                }



            </div>
            {hasControls ?
                <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a> : null
            }
            {
                hasControls ?
                    <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> : null
            }
        </div>
    )
}

export default Carousel;