


function Description() {
    return (
        <div id="description" style={{padding: "0px 0px"}} >
    
            <div>
                <div style={{textAlign: "center"}} className="row bg-light align-items-center">
                    <div style={{marginLeft: "0", marginRight: "0",  paddingLeft: "0px !important",paddingRight: "0px !important"}} className="col-sm no-padding">
                        <img style={{width: "100%"}} src="https://via.placeholder.com/800x600.png"/>
                    </div>
                    <div style={{marginLeft: "0", marginRight: "0",  paddingLeft: "0px !important",paddingRight: "0px !important"}} className="col-sm align-middle no-padding">
                        <p style={{width: "80%", margin: "0 auto"}}> some text to describe what excite distribution is about,
                    we should probably come up with something thats nice to read here that,
                    is a brief statement of what you do.

                    in the meantime, im going to start speaking latin,
                    lorem ipsum lorem ipsum blah blah latin text here to fill the gap</p>
                    </div>
                </div>

                <div style={{textAlign: "center"}} className="row bg-light  align-items-center">
                    <div style={{marginLeft: "0", marginRight: "0", padding: "0px !important"}} className="col-sm no-padding">
                    <p style={{width: "80%", margin: "0 auto"}}> some text to describe what excite distribution is about,
                    we should probably come up with something thats nice to read here that,
                    is a brief statement of what you do.

                    in the meantime, im going to start speaking latin,
                    lorem ipsum lorem ipsum blah blah latin text here to fill the gap</p>
                    </div>
                    <div style={{marginLeft: "0", marginRight: "0",  padding: "0px !important"}} className="col-sm no-padding">
                        <img style={{width: "100%"}} src="https://via.placeholder.com/800x600.png"/>
                    </div>
                </div>

                <div style={{textAlign: "center"}} className="row bg-light align-items-center">
                    <div style={{marginLeft: "0", marginRight: "0" ,padding: "0"}} className="col-sm no-padding">
                        <img style={{width: "100%"}} src="https://via.placeholder.com/800x600.png"/>
                    </div>
                    <div style={{marginLeft: "0", marginRight: "0",  padding: "0px !important"}} className="col-sm no-padding">
                      <p style={{width: "80%", margin: "0 auto"}}> some text to describe what excite distribution is about,
                    we should probably come up with something thats nice to read here that,
                    is a brief statement of what you do.

                    in the meantime, im going to start speaking latin,
                    lorem ipsum lorem ipsum blah blah latin text here to fill the gap</p>
                    </div>
                </div>
            </div>
    </div>
    )
}

export default Description;