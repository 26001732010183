function Footer() {
    return (
        <>
            <div className="footer-header navbar-light bg-dark-excite bg-dark-excite  align-bottom" >
                <div>
                    Put whatever you want here....

                </div>
                <div>
                    Put whatever you want here....

                </div>
                <div>
                    Put whatever you want here....

                </div>
            </div>

            <div className="footer-background  bg-dark-excite bg-dark-excite  align-bottom">
                <div>

                    Quick Links
            </div>
            <div style={{ width: "100%"}}>
                <hr style={{borderTop: "2px dotted white", width: "80%"}}/>
            </div>
                <div>
                    Terms, copyright. privacy policy
            </div>
            </div>
        </>
    )
}

export default Footer;